import React from "react";
import Container from "react-bootstrap/Container";
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { uBrewingData, uTappingData, uBottlingData } from "../utensilsData";



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F5F5F5',
    ...theme.typography.body4,
    padding: theme.spacing(2.0),
    color: theme.palette.text.primary,
  }));



function Utensils(){

    return(
        <div>
            <Container className="uContainer">
                <hr></hr>
                <h1>Alles für´s brauen</h1><br></br>
                <Masonry columns={{sm: 1, md: 2, lg: 2}} spacing={1} className="uMasonry">            
                    {uBrewingData.map((item, index) => (
                        <Item key={item.id}>
                            <h1 className="mb-5 mt-3">{item.title}</h1>
                            <article className="uArticle">
                                    {item.content1 !== "" &&
                                    <p>
                                    {item.content1}
                                    </p>}
                                    {item.image1 !== "" &&
                                    <img src={item.image1} alt="" />}
                                    {item.content2 !== "" &&
                                    <p>
                                    {item.content2}
                                    </p>}
                                    {item.image2 !== "" &&
                                    <img src={item.image2} alt="" />}
                                    {item.content3 !== "" &&
                                    <p>
                                    {item.content3}
                                    </p>}
                                    {item.image3 !== "" &&
                                    <img src={item.image3} alt="" />}
                                    {item.content4 !== "" &&
                                    <p>
                                    {item.content4}
                                    </p>}
                                    {item.image4 !== "" &&
                                    <img src={item.image4} alt="" />}
                                    {item.content5 !== "" &&
                                    <p>
                                    {item.content5}
                                    </p>}
                                    {item.image5 !== "" &&
                                    <img src={item.image5} alt="" />}
                                    {item.content6 !== "" &&
                                    <p>
                                    {item.content6}
                                    </p>}
                                    {item.image6 !== "" &&
                                    <img src={item.image6} alt="" />}
                            </article> 
                        </Item>
                    ))} 
                </Masonry>
                <hr></hr>
                <h1>Alles für´s zapfen</h1><br></br>
                <Masonry columns={{sm: 1, md: 2, lg: 2}} spacing={1} className="uMasonry">            
                    {uTappingData.map((item, index) => (
                        <Item key={item.id}>
                            <h1 className="mb-5 mt-3">{item.title}</h1>
                            <article className="uArticle">
                                    {item.content1 !== "" &&
                                    <p>
                                    {item.content1}
                                    </p>}
                                    {item.image1 !== "" &&
                                    <img src={item.image1} alt=""/>}
                                    {item.content2 !== "" &&
                                    <p>
                                    {item.content2}
                                    </p>}
                                    {item.image2 !== "" &&
                                    <img src={item.image2} alt="" />}
                                    {item.content3 !== "" &&
                                    <p>
                                    {item.content3}
                                    </p>}
                                    {item.image3 !== "" &&
                                    <img src={item.image3} alt="" />}
                                    {item.content4 !== "" &&
                                    <p>
                                    {item.content4}
                                    </p>}
                                    {item.image4 !== "" &&
                                    <img src={item.image4} alt="" />}
                                    {item.content5 !== "" &&
                                    <p>
                                    {item.content5}
                                    </p>}
                                    {item.image5 !== "" &&
                                    <img src={item.image5} alt="" />}
                                    {item.content6 !== "" &&
                                    <p>
                                    {item.content6}
                                    </p>}
                                    {item.image6 !== "" &&
                                    <img src={item.image6} alt="" />}
                            </article> 
                        </Item>
                    ))} 
                </Masonry>   
                <hr></hr>
                <h1>Alles für´s abfüllen</h1><br></br>
                <Masonry columns={{sm: 1, md: 2, lg: 2}} spacing={1} className="uMasonry">            
                    {uBottlingData.map((item, index) => (
                        <Item key={item.id}>
                            <h1 className="mb-5 mt-3">{item.title}</h1>
                            <article className="uArticle">
                                    {item.content1 !== "" &&
                                    <p>
                                    {item.content1}
                                    </p>}
                                    {item.image1 !== "" &&
                                    <img src={item.image1} alt="" />}
                                    {item.content2 !== "" &&
                                    <p>
                                    {item.content2}
                                    </p>}
                                    {item.image2 !== "" &&
                                    <img src={item.image2} alt="" />}
                                    {item.content3 !== "" &&
                                    <p>
                                    {item.content3}
                                    </p>}
                                    {item.image3 !== "" &&
                                    <img src={item.image3} alt="" />}
                                    {item.content4 !== "" &&
                                    <p>
                                    {item.content4}
                                    </p>}
                                    {item.image4 !== "" &&
                                    <img src={item.image4} alt="" />}
                                    {item.content5 !== "" &&
                                    <p>
                                    {item.content5}
                                    </p>}
                                    {item.image5 !== "" &&
                                    <img src={item.image5} alt="" />}
                                    {item.content6 !== "" &&
                                    <p>
                                    {item.content6}
                                    </p>}
                                    {item.image6 !== "" &&
                                    <img src={item.image6} alt="" />}
                            </article> 
                        </Item>
                    ))} 
                </Masonry>   
            </Container>
        </div>
    );

};

export default Utensils;