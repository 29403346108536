import React from "react";
import Row from 'react-bootstrap/Row';
import HistoryData from "../historyData";




function History(){

    return(
        <div className="content-container">
            <Row className="hContent">  
                {HistoryData.map(function(historydata){

                   if(historydata.title.length > 1){  
                        if(historydata.image.length > 1){
                            return(<div className="mb-2" key={historydata.id}>
                                        <h1 className="mb-5 mt-3">{historydata.title}</h1>
                                        <img src={historydata.image} alt="" className="pullLeft imgResponsive postImg thumbnail margin10" />
                                        <article className="hArticle"><p>
                                                {historydata.content1}
                                                </p>
                                                <p>
                                                {historydata.content2}
                                                </p>
                                                <p>
                                                {historydata.content3}
                                                </p>
                                                <p>
                                                {historydata.content4}
                                                </p>
                                                <p>
                                                {historydata.content5}
                                                </p>
                                                <p>
                                                {historydata.content6}
                                                </p>
                                        </article> 
                                    </div>);
                        }else{
                            return(<div className="mb-2" key={historydata.id}>
                                        <h1 className="mb-5">{historydata.title}</h1>
                                        <article className="hArticle"><p>
                                                {historydata.content1}
                                                </p>
                                                <p>
                                                {historydata.content2}
                                                </p>
                                                <p>
                                                {historydata.content3}
                                                </p>
                                                <p>
                                                {historydata.content4}
                                                </p>
                                                <p>
                                                {historydata.content5}
                                                </p>
                                                <p>
                                                {historydata.content6}
                                                </p>
                                        </article> 
                                    </div>);
                        }
                    }else{
                        if(historydata.image.length > 1){
                            return(<div className="mb-2" key={historydata.id}>
                                        <img src={historydata.image} alt="" className="pullLeft imgResponsive postImg thumbnail margin10" />
                                        <article className="hArticle"><p>
                                                {historydata.content1}
                                                </p>
                                                <p>
                                                {historydata.content2}
                                                </p>
                                                <p>
                                                {historydata.content3}
                                                </p>
                                                <p>
                                                {historydata.content4}
                                                </p>
                                                <p>
                                                {historydata.content5}
                                                </p>
                                                <p>
                                                {historydata.content6}
                                                </p>
                                        </article> 
                                    </div>);
                        }else{
                            return(<div className="mb-2" key={historydata.id}>
                                        <article className="hArticle"><p>
                                                {historydata.content1}
                                                </p>
                                                <p>
                                                {historydata.content2}
                                                </p>
                                                <p>
                                                {historydata.content3}
                                                </p>
                                                <p>
                                                {historydata.content4}
                                                </p>
                                                <p>
                                                {historydata.content5}
                                                </p>
                                                <p>
                                                {historydata.content6}
                                                </p>
                                        </article> 
                                    </div>);
                        }
                    }
                })}
                
            </Row>
        </div>
    );
};

export default History;