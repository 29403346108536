import React from "react";
import Container from "react-bootstrap/Container";

let currentDate = new Date().getFullYear();

function About(){
    return(
        <div>
            <Container className="abContainer vh-100 px-5">
                <h1>About me</h1> 
                <br />
                <br />
                <span>
                    Mein Name ist Kai und ich bin Teil einer Gruppe von Hobbybrauern, die ihr erstes Bier vor rund {currentDate - 2019} Jahren gebraut hat. In dieser Gruppe versammeln sich Techniker, Programmierer, Automechaniker, ein Physiotherapeut, Ingenieure und Industriemechaniker, um der Braukunst nachzugehen.
                </span><br /><br />
                <span>
                    Die Vatertagswanderung hat den Anstoß gegeben. Zu diesem besonderen Ereignis hatten wir die Idee ein eigenes Bier zu brauen. Ein Nachbar, selbst Hobbybrauer, hat uns dabei unterstützt und uns die Grundlagen und den Ablauf erklärt. Dabei hatten wir jede Menge Spaß und einige Zeit später uns dazu entschlossen eigenes Equipment anzuschaffen.
                </span><br /><br />
                <span>
                    Seither ist unser Hobby immer weiter gewachsen: Wir probieren gerne neue Sorten aus und freuen uns über jedes neue Bier, das wir brauen können. Es ist für uns aber auch ein geselliges Zusammensein mit Freunden.
                </span><br /><br />
                <span>
                    Zu so mancher Feier gab es dann auch unseren Gerstensaft aus dem Fass. Meistens jedoch füllen wir das Bier in Flaschen und genießen es selbst oder teilen es mit der Familie.           
                </span><br /><br />
                <span>
                    Mit dieser Homerpage versuche ich auch anderen das Brauen näher zu bringen und zeige welche Utensilien für einen Start nötig sind. 
                </span><br /><br />
            </Container>
        </div>
        );
};
    
export default About;