import Alert from 'react-bootstrap/Alert';

function NoPage(){

   return( 
    <div className='content-container'>
        <Alert variant="danger" className="m-5">
          <Alert.Heading>404!!</Alert.Heading>
            <h1>Ups... Page not Found!!</h1>
        </Alert>
    </div>)
};

export default NoPage;

