import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar';
import headerImage from "../img/foamAbst1.png"
import Logo from "../img/eb-logo-bktp-bgnd.png";

function Header(){

    var sectionStyle = {
        backgroundImage: `url(${headerImage})`,
        minHeight: "100%",
        minWidth: "100%",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        overflowY: "hidden",
        overflowX: "hidden"
     }
     

    return(
        <header className="App-header">
        <div className="navBarHeader" style={sectionStyle}>
            <Navbar collapseOnSelect expand="md" className='pb-4 px-3'>
                <Navbar.Brand href="/">
                    <img src={Logo} type="image/png" width="80" height="80" alt="EB Logo"/>
                    {" "}
                    Expedition Bier
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                    <Nav className="mr-auto text-center bg-white">
                        <Nav.Link href="/history">Geschichte</Nav.Link>
                        <Nav.Link href="/waytobeer">Herstellung</Nav.Link>
                        <Nav.Link href="/utensils">Utensilien</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
      </header> 
    );
};

export default Header;