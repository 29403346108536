import React from "react";
import { Col, Row } from "react-bootstrap";
import Accordion from 'react-bootstrap/Accordion';


function WayToBeer(){

    return(
        <div className="mx-5 my-5 wtbBody">
            <div className="mx-2 mb-5 wtbTitleArea">
                <h1 className="mb-5">Der Weg zum Bier</h1>
                <span><h5>Um ein gutes Bier zu brauen, bedarf es einiger Schritte, die zu beachten sind. Denn nur so erhaltet Ihr ein qualitativ hochwertiges Getränk. Darum ist es wichtig, dass Ihr auch auf Kleinigkeiten achtet. Nur so könnt Ihr sicher sein, dass das Bier nach euren Vorstellungen gelingt.</h5></span>
            </div>
            <Row>
            <Col lg={2} md={1} >
            </Col>
            <Col lg={8} md={10} xs={12}>
                <Accordion className="my-5" defaultActiveKey="0" alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><h3>Mälzen</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/malting.png")} alt="Man in malthouse" />
                                <article className="wtbArticle">
                                <p>Das Mälzen ist ein Schritt, den selbst die meisten Brauereien nicht mehr selbst übernehmen und für Hobbybrauer meistens zu viel Aufwand bedeutet. Es gibt jedoch durchaus Hobbybrauer, die sich damit beschäftigen und sich in einschlägigen Foren darüber austauschen. Ich würde euch aber dazu raten, euch erst mal mit dem Brauprozess vertraut zu machen und erst wenn ihr feststellt, dass euch die Auswahl an gängigen Malzen nicht ausreicht oder nicht euren Geschmack trifft, mit dem Mälzen zu befassen. Dennoch möchtie ich euch hier den Vorgang des Mälzens erklären.</p>
                                <p>Das Mälzen unterteilt sich im Wesentlichen in vier Schritte:</p>
                                    <ol>
                                    <li>Weichen</li>
                                    <li>Keimen</li>
                                    <li>Darren</li>
                                    <li>Putzen des Malzes (entfernen von Wurzeln und Trieben)</li>
                                    </ol>
                                <br />
                                <p>Wer sein Getreide (Gerste, Roggen, Weizen) auch noch selbst anbaut muss es zu allererst noch reinigen und Trocknen, um Schimmelbildung oder ein selbstentzünden des Malzes zu verhindern.</p>
                                <p>Das Korn sollte vor dem Mälzen eine Feuchte von 15% nicht übersteigen. Die Trocknung kann durch gutes belüften des Korns oder unter Verwendung einer Darre geschehen. </p>
                                <p>Danach kann man mit dem Mälzen beginnen.</p>
                                <h4>1.	Weichen</h4>
                                <p>Durch das Einweichen des Braugetreides wird das Korn zum Leben erweckt. Der Wassergehalt des Getreides wird hierfür auf 35-43% angehoben. Das Weichen unterteilt sich in 2 Phasen: das Nassweichen und die Trockenweiche, welche das Malz abwechselnd durchläuft. Beim Nassweichen geht es um die Wasseraufnahme des Korns, während das Trockenweichen der Belüftung dient. Der Prozess des Weichens dauert für gewöhnlich 1-2 Tage.</p>
                                <h4>2.	Keimen</h4>
                                <p>Während der nächsten 4-7 Tage wird dann das Braugetreide in Keimkästen zum Keimen gebracht. Um einen Wassergehalt im Korn von 43-48% zu halten wird es über Wasserdüsen zusätzlich gewässert. Nach ein paar Tagen bildet sich dann ein Keimling und Wurzeln aus. Beim Keimen entsteht zusätzlich Wärme, weshalb es nötig ist das Getreide regelmäßig zu wenden. Hierdurch wird auch eine gute Sauerstoffversorgung des Korns erreicht. </p>
                                <p>Beim Keimen entstehen Enzyme, die später für das Brauen des Bieres wichtig sind. Außerdem wird schon ein Teil der Stärke in Zucker gewandelt. Ist dieser Schritt abgeschlossen erhält man das sogenannte Grünmalz.</p>
                                <h4>3.	Darren</h4>
                                <p>Beim Darren geht es darum das Grünmalz zu trocknen und die Lagerfähigkeit zu erhöhen. Es wird hierfür von einer Feuchte von 40-45% auf 4-5% gesenkt. Außerdem werden die Farb- und Aromastoffe intensiviert. Die Höhe der Temperatur und die Dauer dieser Phase entscheiden über die Malzfarbe und damit über die Farbe des späteren Bieres. Dunkle Malze werden also bei höherer Temperatur und länger gedarrt. Hellere entsprechend weniger lang und bei niedrigerer Temperatur. Das Darren dient des Weiteren dazu, die Wurzeln und Triebe am Korn zu entfernen. Diese vertrocknen und fallen weitestgehend ab.</p>
                                <h4>4.	Putzen des Malzes</h4>
                                <p>In diesem Schritt werden die Wurzelreste und Triebe durch sieben vom Malz getrennt.</p>
                                <br />
                                <p>Damit ist das Mälzen abgeschlossen und nach einer Lagerung von ca. 6 Wochen ist das Malz bereit zu Bier verarbeitet zu werden.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><h3>Schroten</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/schrot_sml.png")} alt="Man in malthouse" />
                                <article className="wtbArticle">
                                <p>Um die im Malz enthaltene Stärke bzw. den Zucker aus dem Korn lösen zu können, muss es geschrotet werden. Da die Größe des gemahlenen Malzes sehr wichtig ist, würde ich euch raten es schroten zu lassen. Die meisten Anbieter von Braumalzen können, gegen einen kleinen Aufpreis, auch schroten. Ich beziehe mein Malz immer bei <a href="https://my-bier.de/50_Malz_geschrotet.html" target="_blank" rel="noreferrer">my-bier.de</a>. Hier gibt es die Möglichkeit die vielen Malzsorten auch gleich zu schroten. Es gibt jedoch auch noch andere Anbieter wie z.B. <a href="https://www.hobbybrauerversand.de/Malz_s3">hobbybrauversand.de</a> oder <a href="https://gastro-brennecke.de/bier-brauen/bier-brauen-zutaten/malz-bier/braumalz/?gclid=EAIaIQobChMIkYjR19q4_AIVmYxoCR3CHQfJEAAYASAAEgJ5fvD_BwE&p=1">gastro-brennecke.de</a>.</p>
                                <p>Die Größe des Schrotes ist später beim Abläutern wichtig. Ist das Schrot zu grob lässt sich eure Maische zwar gut läutern aber die Stärke und der Zucker lösen sich schlecht aus dem Korn. Ist es zu fein, verstopft euer Läuterbottich und die Würze kann nicht mehr abfließen. Glaubt mir, ich habe diese Erfahrung schon gemacht.</p>
                                <p>Um jedoch das Thema abzuschließen gehe ich nochmal auf das Schroten ein. Hierbei wird das Malz durch zwei Walzen aufgedrückt. Die Walzen stehen gerade so weit auseinander, damit das Korn aufgedrückt aber nicht zerquetscht wird, damit sich die Stärke und der Zucker lösen und der Getreidekörper beim Läutern als natürlicher Filter dienen kann.</p>
                                <p>Es gibt solche Schrotmühlen auch käuflich zu erwerben.</p>   
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><h3>Maischen</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/mash_sml.png")} alt="Mash in pot" />
                                <article className="wtbArticle">
                                <p>Das Gemisch von Braumalz und Wasser nennt man Maische. Das Maischen ist ein wichtiger Schritt beim Brauen. Hierbei wird Stärke und Eiweiße aus dem Malz gelöst und in Zucker zerlegt.</p>
                                <p>Damit dies geschieht wird die Maische stufenweise erhitzt. Die Temperatutstufen werden Rasten genannt. Während den Rasten wird die Heizplatte / Gasbrenner abgeschaltet und die Maische ohne Rühren im Topf, bei geschlossenem Deckel, ruhen gelassen. Je nach Temperatur und Dauer der Rast können bestimmte Enzyme arbeiten und die Stärke in vergärbaren und nicht vergärbaren Zucker wandeln. Die Temperaturstufen sind genau einzuhalten, da die jeweils wirkenden Enzyme nur ein kleines Temperaturfenster haben, um arbeiten zu können. Ist die Temperatur zu hoch oder zu niedrig sinkt die Aktivität der Enzyme und es wird weniger Stärke zu Zucker. </p>
                                <p>Gleichzeitig mit dem Aufheizen des Hauptgusses kann auch der Nachguss auf eine Temperatur von max. 78°C gebracht werden (Der Nachguss sollte die gleiche Temperatur haben, wie der Hautpguss am Ende des Maischens). </p>
                                <h4>Einmaischen/Eiweißrast:</h4>
                                <p>Zu Beginn wird das Wasser (der Hauptguss) auf die Einmaischtemperatur (ca. 52-55°C) gebracht. Ist das Wasser auf Temperatur kann das Malz dazu gegeben werden. Dabei muss das Malz gut untergerührt werden. Danach kann man die Maische 10 min. ruhen lassen. Das ist dann gleich die erste Rast, die sogenannte Eisweißrast. Diese wird bei ca. 45-55°C durchgeführt. Die ersten Enzyme fangen an zu arbeiten und zerlegen hochmolekulare Eiweiße in niedermolekulare. Allerdings kann die Eiweißrast auch ausgelassen werden und sie wird nicht in allen Braurezepten berücksichtigt.</p>
                                <h4>Maltoserast:</h4>
                                <p>Danach wird die Maische unter ständigem Rühren auf die nächste Temperaturstufe aufgeheizt. Beim Rühren solltet ihr darauf achten, dass ihr auch das Malz das sich am Boden absetzt mit verrührt. Sonst könnte es anbrennen. Die Maltoserast wird bei einer Temperatur von 60-65°C erreicht. Je nach Rezept bewegt sie sich zwischen 20-40 Minuten. In dieser Temperaturstufe wirkt besonders die β-Amylase (Beta-Amylase). Sie Spaltet die Stärke in vergärbaren Zucker auf (Der bringt nachher den Spaß ins Bier).</p>
                                <h4>Endverzuckerung:</h4>
                                <p>Nach der Rastzeit wird unser „Bier“ weiter erhitzt. Diesmal auf eine Temperatur von 70-75°C. Hierbei handelt es sich um die Endverzuckerung. Durch das erhitzen hört die β-Amylase auf zu wirken und die α-Amylase (Alpha-Amylase) kann mir der Arbeit beginnen. Es entsteht unvergärbarer Zucker. Dieser verleiht eurem Bier die Vollmundigkeit. Die Endverzuckerung dauert meist um die 20 Min. und wird durch die Jodprobe Abgeschlossen.</p>                           
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><h3>Jodprobe</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>Um zu erkennen, ob die Stärke vollständig in Zucker gewandelt wurde, wird eine Jodprobe durchgeführt. Jod färbt sich in Verbindung mit Stärke Blau / Violett bis Schwarz. Das Bedeutet also, verfärbt sich das Jod nicht wurde die Stärke vollständig in Zucker aufgespalten. Die Vorgehensweise ist wie Folgt: Es wird eine kleine Menge, die ihr z.B. mit einem Esslöffel entnehmen könnt, der Maische auf einen weißen Teller / Untertasse gegeben und 1-2 Tropfen Jod hinein getropft. Tritt die genannte Verfärbung auf muss die Rast um 5-10 Min. verlängert und danach die Jodprobe wiederholt werden.</p>
                                <p>Bleibt die Probe wie sie vor dem einträufeln des Jods war ( Jodnormal ), ist es Zeit die Maische ein letztes Mal zu erwärmen (auf 78°C). Damit wird die Aktivität aller Enzyme eingestellt. Jetzt ist es Zeit für das Abläutern.  </p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><h3>Läutern</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/abmaischen_sml.png")} alt="lautering the mash" />
                                <article className="wtbArticle">
                                <p>Das Läutern beginnt mit dem Umfüllen der Maische (Flüssiger Anteil sowie alle festen Bestandteile) aus dem Sudkessel in den Läuterbottich. Der Sudkessel muss dann gut gereinigt werden. Dieser wird gleich wieder benötigt.</p>
                                <p>Ist alle Maische im Läuterbottich beginnt eine 10-minütige Läuterruhe. Das gibt dem Treber (feste Bestandteile der Maische) Zeit sich abzusetzen. Das ist wichtig, da sich hierdurch ein natürlicher Filter bildet. Dann kann die Vorderwürze ablaufen gelassen werden. Dazu lässt man ca. 6L Würze (flüssiger Bestandteil der Maische) aus dem Läuterbottich ablaufen. Doch die Würze wird nicht verschwendet, sondern in einem Gefäß aufgefangen und vorsichtig zurück in den Läuterbottich gekippt. Es lässt sich recht schnell feststellen, dass die ablaufende Flüssigkeit klarer wird. Durch dieses Vorgehen verdichtet sich der Treber und die Filterwirkung wird merklich verbessert.</p>
                                <p>Dann kann Abgeläutert werden. Der Ablaufhahn wird geöffnet und die Würze wird in den Sudkessel abgefüllt. Jetzt ist auch der Moment für den Nachguss gekommen. Beim Maischen haben wir ziemlich viel Wasser durch das Erwärmen, in Form von Dampf und durch das vollsaugen des Malzes verloren. Dieser Verlust wird durch den Nachguss ausgeglichen. Außerdem spülen wir so noch mehr des gelösten Zuckers aus dem Treber in unsere Würze.</p>
                                <p>Ist ein Teil der Würze aus dem Läuterbottich abgelaufen, muss immer wieder Wasser aus dem Nachguss nachgeschüttet werden. Das muss mit Vorsicht geschehen, um nicht alles allzu sehr aufzuwirbeln. Der Treber muss immer mit Wasser bedeckt sein, da er sich sonst zu sehr verdichtet und keine Würze mehr durchlässt. Sobald der Nachguss Komplett im Läuterbottich und alle Flüssigkeit durchgelaufen ist, ist das Abmaischen beendet. Die Würze kann jetzt gekocht werden.</p>
                                <p>Der Treber hat jetzt ausgedient und kann weg. Er ist allerdings ein erstklassiges Tierfutter – Schafe, Schweine oder Pferde werden sich freuen. Man kann ihn aber auch zum Brot backen verwenden – wie das geht, weiß bestimmt der Bäcker eures Vertrauens.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><h3>Würze kochen</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                {/* <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/malting.png")} alt="Man in malthouse" /> */}
                                <article className="wtbArticle">
                                <p>Sobald das Läutern beendet ist geht es ans Würze Kochen. Dabei wird die Würze, wie der Name des Schrittes schon sagt, zum Kochen gebracht. Das ist wichtig, da damit die Würze sterilisiert wird und übrige, unerwünschte Eiweiße verklumpen und ausfallen. Das macht sich durch eine Schaumbildung auf dem Sud bemerkbar. Diesen Schaum gilt es in regelmäßigen Abständen abzuschöpfen. Das Kochen dauert für gewöhnlich 1-2 Stunden und muss bei offenem Deckel stattfinden, da sonst herausgelöste Stoffe zurück in die Würze Tropfen. Den Wasserverlust kann man, falls nötig, bis zu einer halben Stunde vor Kochende noch mit Nachguss-Wasser oder evtl. noch vorhandener Würze auffüllen. Man muss sich beim Auffüllen mit Wasser aber darüber im Klaren sein, daß hierdurch auch die Würze verwässert wird und man den angepeilten Stammwürzegehalt ( die Stammwürze besteht aus Malzzucker, Eiweißen, Vitaminen, Mineralien und Aromastoffen ) nicht mehr erreicht. Das macht sich dann auch im Alkoholgehalt des Bieres bemerkbar – also Vorsicht!</p>
                                <p>Am besten ihr lasst beim Abläutern ca. 5-10L zusätzlichen Nachguss durch den Treber laufen und fangt die dadurch gewonnene Würze in einem separaten Behälter auf, um sie später zum Auffüllen der Hauptwürze verwenden zu können. Diese Würze enthält zwar nicht mehr so viel Zucker, aber euer Stammwürzegehalt wird hierdurch nicht so stark gesenkt wie durch reines Wasser. Rechnet also auf den eigentlichen Nachguss die entsprechende Menge Wasser oben drauf. Bei diesem Vorgehen müsst ihr aber genau darauf Achten, wieviel Wasser ihr beim Läutern für den Nachguss verwenden müsst und wieviel Wasser ihr für das Auffüllen vorgesehen habt.</p>
                                <p>Mit dieser Methode habe ich bisher immer recht exakt meine angestrebte Menge Bier und den Stammwürzegehalt erreicht.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><h3>Hopfengabe</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>Auch hier ist ziemlich eindeutig was zu tun ist. Der Hopfen kommt ins Bier! Dabei wird nicht der Ganze Hopfen auf einmal in die Würze gegeben. Sobald die Würze Kocht wird der Bitterhopfen hinzugegeben. Der Hopfen sollte immer sehr Vorsichtig und schubweise in den Sud gegeben werden, da es zu heftigem aufkochen und überschäumen der Würze kommen kann.</p>
                                <p>Da der Bitterhopfen lange mitgekocht wird, lösen sich hier die Bitterstoffe des Hopfens und die Aromastoffe verfliegen. Es entsteht also die Bittere des Bieres. </p>
                                <p>Kurz vor Kochende kommt der Aromahopfen hinzu. Auch hier ist Vorsicht bei der Gabe geboten. Der Hopfen kann wegen der kurzen Kochzeit seine Aromastoffe abgeben, die Bitterstoffe jedoch haben keine Zeit sich zu lösen. Es Entsteht das Hopfenaroma und der Duft des Bieres.</p>
                                <p>Der Hopfen trägt außerdem dazu bei das Bier haltbarer zu machen und dadurch die Lagerfähigkeit zu verlängern. Daher sind Export-Biere immer etwas herber im Geschmack.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="7">
                        <Accordion.Header><h3>Ausschlagen</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3 pb-5">
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/ausschlagen_sml.png")} alt="Man in malthouse" />
                                <article className="wtbArticle">
                                <p>Nach dem Kochen müssen die nicht löslichen Bestandteile des Hopfens und ausfallende Eiweiße aus der Würze entfernt werden. Das geschieht im sogenannten Whirlpool. Bei diesem Verfahren wird die Würze (Ausschlagwürze) durch rühren in Drehung versetzt. Durch die Drehbewegung bildet sich in der Mitte des Sudkessels ein Kegel mit allen festen Bestandteilen des Hopfens, Eiweißen und Trübstoffen (Heißtrub). Nachdem sich die Flüssigkeit beruhigt hat (ca. 20-30 min) kann die klare Würze vorsichtig seitlich durch den Ablasshahn abgezogen werden.</p>
                                <p>Ich selbst habe dieses Verfahren jedoch noch nie angewandt. Ich spanne immer einen Farblosen, Naturbelassenen Kissenbezug aus Baumwolle in den Läuterbottich. Der wird dann unter dem Auslasshahn des Sudkessels platziert. Unter dem Auslasshahn des Läuterbottichs kommt dann das Gärfass. Danach werden beide Ablasshähne geöffnet. Die Würze fließt aus dem Sudkessel, durch den Kissenbezug in den Läuterbottich. Dadurch werden alle festen Bestandteile herausgefiltert. Die klare Würze kann dann durch den offenen Hahn des Läuterbottichs direkt in das Gärfass ablaufen.</p>
                                <p></p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="8">
                        <Accordion.Header><h3>Kühlung</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>Das kühlen der Würze wird benötigt, um möglichst schnell auf die Anstelltemperatur (18-24°C für obergärige und 10-15°C für untergärige Hefe) für die Hefe zu kommen und somit das Risiko der Verunreinigung des Bieres mit Keimen oder Schimmelpilzen zu verringern. </p>
                                <p>Viele Hobbybrauer verwenden hierfür einen Eintauchkühler, durch den kaltes Wasser gepumpt wird. Es kann aber auch durch eintauchen gefrorener Wasserflaschen oder Eispacks, in die Würze, dasselbe Ergebnis erreicht werden (Aber vorher gut sterilisieren).</p>
                                <p>Hier gehe ich auch einen anderen Weg. Da mein Bier bereits im Gärfass ist, verschließe ich es gut und lasse es einfach über Nacht abkühlen. Am nächsten Morgen, nach dem prüfen der Temperatur, kann dann die Hefe ins Bier. Das hat bisher immer gut funktioniert und ich war noch nie mit einer Verunreinigung konfrontiert.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="9">
                        <Accordion.Header><h3>Anstellen</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>Jetzt ist es soweit. Die Hefe hat ihren Einsatz. Ist die Würze auf die erforderliche Temperatur herunter gekühlt, kann die Hefe hinzugegeben werden. Der Vorgang wird Anstellen genannt. </p>
                                <p>Für die Menge empfiehlt eine alte Brauerregel die Zugabe von 0,5-1% dickbreiiger Hefe bezogen auf das Würzevolumen. Das bedeutet bei einer Würzemenge von 50l ca. 250-500ml Hefe. Ich habe mich bisher immer nach den Angaben auf der Packung gerichtet und dementsprechend die Hefemenge berechnet. Hier kann man für gewöhnlich die Dosierung und die nötige Gärtemperatur nachlesen.</p>
                                <p>Wird von euch eine Trockenhefe verwendet, muss diese vorher rehydriert werden, d.h. der Hefe muss das bei der Herstellung, entzogene Wasser wieder zugeführt werden. Hierfür kann man einen Teil der Würze oder vorher abgekochtes Wasser verwenden. Die genaue Menge Flüssigkeit und benötigte Temperatur könnt ihr auch auf der Packung der Hefe finden.</p>
                                <p>Solltet ihr Flüssighefe verwenden, habt ihr es leichter. Diese wird in einer Packung mit einer bestimmten Menge Würze geliefert. Innerhalb dieser Packung schwimmt ein kleines Päckchen mit Hefe, das durch gezieltes darauf schlagen zum Platzen gebracht wird. Dann gut Schütteln. Die Hefe verteilt sich in der Würze und wird aktiviert. Schon nach 1-2h bläht sich die Packung mit der Hefe-Würze-Mischung auf und ihr wisst, die Hefe ist aktiv. Auch hier steht die genaue Vorgehensweise und Anstelltemperatur auf der Verpackung. Nachteil der Flüssighefe: sie ist nicht so lange haltbar wie Trockenhefe und muss kühl gelagert werden – am besten im Kühlschrank.</p>
                                <p>Bevor nun die Hefe in die Würze kippt wird, sollte diese noch einmal gut belüften werden. Das kann durch Muskelkraft und einen Schneebesen erfolgen. Dabei rührt man die Würze einmal gut durch. Wichtig ist dass der Schneebesen gut desinfiziert wurde. Am besten mal in Kochendes Wasser tauchen. Der so eingebrachte Sauerstoff ist wichtig für die Vermehrung der Hefe und schadet dem Bier überhaupt nicht. Die Hefe verdrängt bei ihrer Vermehrung unerwünschte Organismen.</p>
                                <p>Ich habe auch schon gelesen, dass manche für das Einbringen von Sauerstoff eine Aquarienpumpe verwenden. Auch hier ist auf Sauberkeit zu achten! Leider habe ich hiermit keine Erfahrung und kann auch nicht berichten welche Methode die bessere ist.</p>
                                <p>Nachdem die Würze belüftet wurde, kann die Hefe eingebracht werden.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="10">
                        <Accordion.Header><h3>Gärung</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>In diesem Schritt findet der Zauber statt und es wird aus Würze Bier. Die Gärung unterteilt sich in 2 Phasen. Die Hauptgärung und die Nachgärung. In beiden Phasen wird der Vergärbare Zucker in Alkohol und Kohlensäure gewandelt. Die Gärung ist abgeschlossen sobald aller vergärbarer Zucker umgewandelt wurde. Dabei muss man darauf achten, dass man am Ende der Hauptgärung noch genug Zucker zur Karbonisierung (Nachgärung / Zweitgärung) des Bieres übrig hat. Hier kommt nämlich die Kohlensäure ins Bier.</p>
                                <p>Aber zunächst schauen wir uns den Unterschied von obergärigem und untergärigem Bier an. Der ist eigentlich ganz einfach erklärt:</p>
                                <p>Obergärige Biere werden bei einer Temperatur von 15-22°C vergoren und die Hefe schwimmt während des Gärprozesses oben auf. Obergärige Biersorten sind z.B. Kölsch, Ale oder Weizenbier.</p>
                                <p>Untergärige Biere hingegen benötigen eine Temperatur von 5-12°C. Die Hefe sinkt hier während der Gärung ab. Dabei entstehen Biere wie Pilsener oder Lagerbiere.</p>
                                </article>
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/gärfass_sml.png")} alt="fermentation barrel" />
                                <article className="wtbArticle">
                                <h4>Hauptgärung</h4>
                                <p>Die Hauptgärung beginnt sobald die Hefe in der Würze ist. Ab jetzt wird unser zukünftiges Bier konstant bei der benötigten Gärtemperatur gehalten. Abhängig von der Temperatur, der Hefe und der zugegebenen Menge an Hefe kommt es normalerweise nach 8-12 Stunden zu einer Schaumbildung (Kräusen) an der Oberfläche der Würze. Manche Hefen sind etwas schneller als andere. Die Schaumbildung nennt der Brauer das Ankommen der Gärung. Dabei ist das Ankommen noch einmal ein kritischer Moment. Denn während die Hefe mit dem vergären beginnt, können sich auch noch ungewollte Keime oder Pilze im Bier breit machen. Es dauert eine Zeit bis die Hefe sich so stark vermehrt hat, dass sie die Oberhand hat und diesen Keimen keine Chance mehr lässt sich zu vermehren. Spätestens nach 48 Stunden sollte die Gärung angekommen sein.</p>
                                <p>Dann ist es Zeit die Schnellvergärprobe zu entnehmen. Dabei füllt man eine kleine Menge der Würze in ein separates Gefäß. Am besten einen hohen Messzylinder aus Glas, wie man ihn aus Laboren kennt. Dann wird die Probe abgedeckt an einen warmen (20-25°C) Ort gestellt. Durch die Wärme vergärt die Würze schneller und man kann den Vergärgrad feststellen, der sich einstellt wenn die Würze komplett durchgegoren ist. Hierfür wird der Stammwürzegehalt gemessen. Dieser setzt sich aus dem vergärbaren Teil und dem unvergärbaren des in der Würze gelösten Zuckers zusammen. Ist die Gärung beendet bleibt somit nur noch der unvergärbare Zucker übrig. Über den Stammwürzegehalt kann auch annähernd der Alkoholgehalt ermittelt werden. Dafür gibt es Rechner im Internet auf die man zurückgreifen kann.</p>
                                <p>Der Stammwürzegehalt kann z.B. mit einer Bierwürzspindel messen werden. Dazu wird die Bierspindel in die schnellvergärprobe getaucht. Durch den in der Probe enthalten Zucker schwimmt die Spindel unterschiedlich auf und man kann, nachdem die Spindel ruhig in der Flüssigkeit steht, auf der Skala den Stammwürzegehalt ablesen. Diesen sollte man jetzt in regelmäßigen Abständen messen. </p>
                                <p>Man kann sich aber auch eines Refraktometers bedienen, um den Stammwürzegehalt zu messen. Dieses ist präziser als die Bierspindel. Allerdings muss man beachten, dass der gemessene Wert durch den entstanden Alkohol in der Probe verfälscht wird!! Aber kein Grund zu verzweifeln. Es gibt im Internet Korrekturtabellen oder Rechner, mit denen sich dann der korrekte Stammwürzegehalt ablesen bzw. ermitteln lässt.</p>
                                <p>Verändert sich der gemessene Wert 2-3 Tage nicht mehr, kann man davon ausgehen, dass die Gärung abgeschlossen ist. Nun weiß man bei welchem Wert der Hauptsud fertig vergoren und es Zeit zum Schlauchen (Abfüllen des Jungbieres in ein Druckfestes Gefäß z.B. ein Keg-Fass) ist.</p>
                                </article>
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/schlauchen_sml.png")} alt="decant" />
                                <article className="wtbArticle">
                                <h4>Schlauchen</h4>
                                <p>Schlauchen nennt man das umfüllen des Jungbieres in ein für die Nachgärung geeignetes Gefäß. In der Regel sind das Bügelflaschen, ein Keg-Fass oder ein Soda-Keg. Hier wird das Bier bis zur vollständigen Reife gelagert.</p>
                                
                                <h4>Nachgärung im Keg- oder Sodafass</h4>
                                <p>Ich fülle mein Jungbier zur Nachgärung in ein Keg-Fass. Dafür schlauche ich das Bier bevor es vollständig gegoren ist (Grünschlauchen). Da in einem Druckfass das CO2 nicht mehr entweichen kann, löst es sich im Bier und es entsteht die benötigte Kohlensäure. Um den Moment des Grünschlauchens (die Grünschlauchreife) festzustellen, benötigt man den Endvergärgrad (Stammwürzegehalt nach der Gärung) der bei der Schnellvergärprobe festgestellt wurde. Um noch genügend Zucker im Bier zu haben schlaucht man das Bier x°P über dem Endvergärgrad.</p>
                                <p>Den richtigen Zeitpunkt zum Grünschlauchen kann man jetzt errechnen. Zum besseren Verständnis machen wir hier eine Beispielrechnung:</p>
                                <p>Als erstes müssen wir wissen welchen Kohlensäuregehalt das Bier haben soll. Dieser unterscheidet sich je nach Biersorte und kann in div. Foren, wie <a href="https://hobbybrauer.de/forum/wiki/doku.php/co2-gehalt_verschiedener_biersorten" target="_blank" rel="noreferrer">hier</a>, nachgelesen werden.</p>
                                <p>Nehmen wir an wir haben ein Pils gebraut. Dann soll der <strong>Kohlensäuregehalt</strong> im Bier <strong>5,5 g/l</strong> betragen.</p>
                                <p>Dann müssen wir wissen welche Menge CO2 sich bei der Hauptgärung bereits im Bier gelöst hat (<a href="https://hobbybrauer.de/forum/wiki/doku.php/co2-saettigungskonzentration" target="_blank" rel="noreferrer">CO2-Sättigungskonzentration</a>). Das ist Temperaturabhängig. Auch hierfür gibt es Tabellen, siehe CO2-Sättigungskonzentration.</p>
                                <p>Angenommen wir haben das Bier bei 12°C gären lassen. Nach der oben genannten Tabelle hat sich im Bier dann ein CO2-Gehalt von 2,13 g/l eingestellt. Das bedeutet uns fehlen noch 3,37 g/l CO2 im Bier.</p>
                                <p>Nun müssen wir noch wissen, dass <strong>pro 0,1°P ca. 0,4g/l CO2</strong> entstehen. Also gut merken!! </p>
                                <p>Hier die Formel: <math xmlns="http://www.w3.org/1998/Math/MathML"><mi>x°P</mi><mo> = </mo><mfrac bevelled="true"><mi>3,37g/l * 0.1°P</mi><mi>0,4g/l</mi></mfrac></math></p>
                                <p>Das bedeutet wir müssen <strong><u>0,84 °P</u></strong> über dem Endvergärgrad das Bier Schlauchen. Dabei ist es wichtig den Schlauch bis an den Boden des Fasses reichen zu lassen. Am besten legt man am Boden einen kleinen bogen, damit das Bier schön sachte in das Fass laufen kann. Würde es hinein plätschern würde die bereits gelöste Kohlensäure verloren gehen. Am Ende des Umfüllens muss außerdem gut darauf geachtet werden, dass nichts von dem im Gärfass abgelagerten Bodensatzes mit ins Keg gelangt!! Das ist bereits abgestorbene Hefe und div. Trubstoffe die das Bier verderben können.</p>
                                <p>Wer keine Lust hat alles mathematisch exakt zu ermitteln, kann auch auf eine π*Daumen-Methode zurückgreifen. Hierbei wird 1°P über dem Endvergärwert Grüngeschlaucht. Dann wird ein Spundadapter mit Überdruckventil an den CO2-Anschluss des Zapfkopfes des Keg-Fasses angeschlossen. <strong>ACHTUNG! Ihr müsst eine Blindkappe oder einen Absperrhahn an dem Bierabgang des Zapfkopfes anbringen, sonst habt ihr eine Bierfontäne in eurem Keller!!!</strong></p>
                                <p>Das Überdruckventil am Spundadapter wird dann auf einen bestimmten Fassdruck (Sättigungsdruck) eingestellt. Bei diesem Druck löst sich genau die Menge CO2 im Bier die wir erzielen wollen. Auch dafür gibt es <a href="https://www.maischemalzundmehr.de/index.php?inhaltmitte=toolsspeiserechner" target="_blank" rel="noreferrer">Rechner</a> mit dem man den richtigen Druck bei der passenden Temperatur entnehmen kann. Für unser Beispiel bedeutet das, nach dem oben genannten Rechner, um die benötigten 5,5g/l CO2 bei 12°C ins Bier zu bekommen, muss der Druck im Fass 1,6Bar betragen. Durch das Überdruckventil wird das überschüssige CO2 abgelassen.</p>
                                <p>Der Spundadapter am Zapfkopf sollte auch verwendet wenn man den exakten Wert zum Grünschlauchen berechnet hat, um damit den Fortschritt der Gärung zu beobachten. Verändert sich der Fassdruck nicht mehr, ist die Nachgärung abgeschlossen und das Bier ist fertig.</p>
                                
                                <h4>Flaschengärung</h4>
                                <p>Es gibt aber auch noch die Möglichkeit eine Flaschengärung durchzuführen. Hierbei wartet man bis die Hauptgärung abgeschlossen ist.</p>
                                <p>Auch hier kommt es auf die bereits im Bier gelöste Menge CO2 an. Diese Können wir ja wieder aus der oben verlinkten Tabelle herauslesen. In unserem Beispiel also 2,13g/l. Um jetzt die benötigte Karbonisierung zu erreichen, kann man Speise oder Zucker vor dem Abfüllen in die Flasche geben.</p>
                                <p>Die Speise wird während dem Brauen, vor der Zugabe der Hefe entnommen und eingefroren. Speise ist also nur Würze die nicht vergoren wurde.</p>
                                <p>Alternativ füllt man eine bestimmte Menge Zucker in die Flasche, um der im Bier enthaltenen Hefe neues Futter zu bieten. Die benötigte Menge Zucker kann man sich z.B. von diesem <a href="https://www.maischemalzundmehr.de/index.php?inhaltmitte=toolsspeiserechner" target="_blank" rel="noreferrer">Rechner</a> ausrechnen lassen. <strong>(Aber Vorsicht: nur noch die benötigte Restmenge an CO2 im Rechner angeben und nicht die gesamte Menge. Da durch die Gärung ja bereits CO2 im Bier enthalten ist, könnte der Druck in der Flasche zu hoch werden und sie zum Platzen bringen.)</strong> Danach werden die Flaschen (am besten Bügelflaschen) verschlossen und zur Nachgärung an einem entsprechend Kühlen Ort gelagert. Bei der Flaschengärung mit Bügelflaschen kann man den Start der Nachgärung Kontrollieren indem man den Deckel einer Flasche leicht öffnet. Nach 1-2 Tagen sollte man dann ein zischen hören. Ihr solltet aber nicht alle paar Tage anfangen Deckel zu lüften. Das Bier braucht seine Ruhe. Die Nachgärung ist dann nach ca. 2-3 Wochen angeschlossen. </p>
                                <p>Bei der Flaschengärung ist es extrem wichtig, dass die Flaschen vor dem Befüllen gut gereinigt sind und Keimfrei gemacht wurden. Sonst kann das Bier in der Flasche verderben. Das kann durch spülen und auskochen der Flaschen erreicht werden. Manch einer Backt die Flaschen auch im Backofen bei ca. 150°C für 15 Minuten aus. <strong>ACHTUNG: Hierbei ist darauf zu achten die Temperatur langsam stufenweise zu erhöhen, da sonst die Flaschen platzen können!</strong></p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="11">
                        <Accordion.Header><h3>Reifung</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>Leider sind wir, nachdem die Gärung abgeschlossen ist, noch nicht am Ziel. Das Bier ist jetzt schon Bier und es bildet sich eine Schaumkrone beim Einschenken. Alkohol und Kohlensäure sind auch enthalten. Also eigentlich alles da was es braucht um Bier zu sein. Aber der Geschmack ist noch nicht ganz Rund. Es sind noch viele Nebenprodukte der Hefe enthalten, die das Bier evtl. unangenehm und Buttersäureartig schmecken lassen. Auch der Hopfengeschmack ist noch zu vordergründig. Außerdem schweben noch viele Trubstoffe im Bier.</p>
                                <p>Um diese „Mängel“ zu beheben wird das Jungbier noch einmal weiter herunter gekühlt und einige Wochen gelagert. Es sollte hierbei auf eine Temperatur von ca. 0°C gekühlt werden. Dabei baut die Hefe einige der eigenen Nebenprodukte ab und sinkt dann schließlich zu Boden. Dadurch klärt sich das Bier.</p>
                                <p>Jede Biersorte hat unterschiedliche Reifezeiten. Während ein Pilsener ca. 4-8 Wochen reift, kann es bei einem Imperial Stout 4-6 Monate dauern. Daher sollte die Reifezeit nicht vernachlässigt werden.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="12">
                        <Accordion.Header><h3>Abfüllen</h3></Accordion.Header>
                        <Accordion.Body>
                            <div className="px-3">
                                <article className="wtbArticle">
                                <p>Wer sein Bier zur Nachgärung und Reifung bereits in die Flasche gefüllt hat ist jetzt schon fertig. Wer sein Bier direkt aus dem Fass Zapfen möchte, ebenfalls. </p>
                                <p>Derjenige, der sein Bier aus dem Fass aber gerne Portionsweise trinkt, der muss jetzt noch einmal ran. Es geht ans Abfüllen!</p>
                                <p>Zunächst benötigt man Flaschen. Klar. Am besten ein paar mehr, denn es ist nicht ganz ausgeschlossen, dass mal eine oder zwei kaputt gehen. Bei mir war das aber eher Aufgrund des heißen Wassers das ich zum Sterilisieren der Flaschen vor dem Abfüllen verwendet habe. Aber dazu später mehr.</p>
                                <p>Hat man die benötigte Menge Flaschen (bei 50l Bier ca. 4 ½ Kisten – eher 5), müssen diese gereinigt werden. Am besten über Nacht einweichen lassen, damit sich angetrocknete Reste entfernen lassen.</p>
                                <p>Ich habe die Flaschen nach dem einweichen mit dem Hochdruckreiniger bearbeitet. Einmal gut durchgespült und danach noch mit der Flaschenbürste durchgeputzt. Gute Ergebnisse habe ich auch durch Zitronensäure erreicht. Hierfür habe ich hoch konzentrierte Zitronensäure in Pulverform in die Flaschen gefüllt. Dazu noch Wasser – Mengenangaben kann man auf der Packung der Säure finden – und über Nacht einwirken lassen. Dann ordentlich ausgespült. Die Säure löst so ziemlich alle Verunreinigungen und tötet zeitgleich auch alle Keime und Pilze ab.</p>
                                <p>Ist man hier nicht gründlich, kann das Bier in der Flasche verderben. Kurz vor dem Abfüllen koche ich die Flasche noch einmal mit heißem Wasser aus. Dazu eine kleine Menge Wasser in die Flasche und schütteln. Aber Vorsicht! Ist die Flasche zu kalt kann sie platzen.</p>    
                                </article>
                                <img className="pullLeft imgResponsive postImg thumbnail margin10 mx-3" src={require("../img/backpressure filler.png")} alt="Man in malthouse" />
                                <article className="wtbArticle">
                                <p>Der Gegendruckabfüller besteht aus einem Füllrohr, dem Dreiwegehahn / Dreiwegeventil, einem Gummistopfen zum abdichten des Flaschenmundes und, je nach Bauart, einem Überdruckventil oder einem Ablasshahn für das Gas (CO2).</p>
                                <p>Und so geht´s: </p>
                                    <ul className="mx-5">
                                        <li>Den Abfülldruck mit nur ca. 0,2 bar mehr als der aktuelle Fassdruck einstellen. Je geringer der Druckunterschied desto besser klappt es mit dem Schaumfreien abfüllen.</li>
                                        <li>Die Bierleitung muss blasenfrei sein. Am besten vor dem Abfüllen ein Glas Bier abfüllen, damit alle Blasen aus der Leitung heraus sind. Sollten sich dann wieder Blasen bilden, den Druck leicht erhöhen.</li>
                                        <li>Die Flasche komplett Vorspannen (Druck einlassen). Das Überdruckventil am Gegendruckabfüller darf nicht abblasen! Dann den Bierhahn komplett öffnen, um Verwirbelungen zu verhindern. Danach das Überdruckventil vorsichtig öffnen, um das Bier einfließen zu lassen. Wenn es gut und Schaumfrei in die Flasche fließt kann das Überdruckventil weiter geöffnet werden, um die Füllgeschwindigkeit zu erhöhen. Aber sachte!</li>
                                        <li>Nachdem die gewünschte Füllhöhe erreicht ist, den Bierhahn schließen und den Druck in der Flasche über das Überdruckventil oder den Ablasshahn ablassen. Dann die Flasche schnell verschließen, damit sich kein Schaum bilden oder Verunreinigungen ins Bier gelangen kann.</li>
                                    </ul>
                                <br />
                                <p>Grundsätzlich gilt auch: je kälter das Bier, umso leichter gelingt das Abfüllen. Und je höher der Abfülldruck, umso schneller kann etwas schief gehen! </p>
                                <p>Das Abfüllen benötigt etwas Übung und viel Gefühl. Nach ein paar Versuchen sollte es aber klappen. Nicht verzweifeln und beirren lassen.</p>
                                </article>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
            <Col lg={2} md={1} >
            </Col>
            </Row>
            <div className="mx-2 my-5 wtbTitleArea">
                <span><h5>Nach der langen Wartezeit und der vielen Arbeit ist das Bier dann aber wirklich fertig und ihr könnt  Stolz auf euch sein – denn ihr habt aus Wasser Bier gemacht!!</h5></span>
                <span><h5>Ihr seht jetzt also, dass Bier nicht einfach nur das schnöde Getränk ist als das es oftmals verrufen ist. Es steckt viel Arbeit und Herzblut darin. Genießt also die Beste Form von Wasser mit jedem Schluck und denkt daran was für einen Spaß es macht so etwas Tolles selbst herzustellen.</h5></span>
            </div>
        </div>
    );
};

export default WayToBeer;