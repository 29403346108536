const uBrewingData = [
    {
        id: 1,
        title: "Braukessel",
        content1: "Der Braukessel oder auch Sudkessel ist das Herzstück bei der Bierherstellung. Hierin wird die Maische auf die nötigen Temperstufen erhitzt und auch die Würze gekocht. Je nach angestrebter Menge sollte er groß genug gewählt werden. Die meisten Hobbybrauer arbeiten mit 20l Ausschlagwürze – wobei Ausschlagwürze die Menge der Würze bezeichnet die man nach dem Kochen für die Gärung zur Verfügung hat. Ihr solltet unbedingt darauf achten, dass der Braukessel etwas mehr Volumen hat, als ihr an Ausschlagwürze gewinnen wollt. D. h. bei 20l solltet ihr einen Topf mit 25-30l und bei 50l einen Topf mit 55-60l Fassungsvermögen wählen.",
        content2: "Der Kessel benötigt dann noch ein Thermometer, damit ihr die Temperatur der Würze stets in Auge behalten könnt:",
        content3: "Und natürlich einen Auslass Hahn (3/4“), damit am Ende die Würze abgelassen werden kann. Dazu benötigt ihr auch noch ein Reduzierstück – entweder mit ¾“ innen- und metrischem Außengewinde oder eines zum einschweißen – damit ihr den Hahn bei Bedarf auch herausnehmen könnt.",
        content4: "Das ganze sollte aus hygienischen Gründen aus Edelstahl sein. Solltet ihr die das Reduzierstück mit Außengewinde wählen, dürft ihr eine Dichtung und Mutter nicht vergessen.",
        content5: ["Wir haben jedoch die Schweißmuffe gewählt, da wir die Variante mit Außengewinde und Mutter wegen der Topf-Krümmung nicht richtig dicht bekommen haben. Dafür benötigt ihr aber jemanden der Edelstahl schweißen kann.", <br />, "Oder ihr greift auf einen fertigen Kessel zurück – der dann aber etwas mehr kostet."],
        content6: "Damit ihr eine Vorstellung des Braukessels bekommt habe ich noch ein Bild von unserer Ausführung:",
        image1: "",
        image2: require("./img/thermometer.png"),
        image3: require("./img/valve_3_4.png"),
        image4: "",
        image5: require("./img/welded_valve.png"),
        image6: require("./img/brewing_kettle.png"),
        link1: "1",
        link2: "https://brauen.de/ss-brewtech-thermometer-fuer-braukessel-mit-schottverschraubung",
        link3: "3",
        link4: "4",
        link5: "5",
    },
    {
        id: 2,
        title: "Läuterbottich",
        content1: "Im Läuterbottich werden die Würze (Flüssige Bestandteile der Maische) von Treber (feste Bestandteile der Maische) getrennt. Dieser sollte genauso groß sein wie der Braukessel, damit der Komplette Inhalt darin Platz findet. An Boden bzw. am Auslasshahn des Bottichs ist eine Läuterschlange (oder Läuterspirale, Läuterhexe) angebracht, damit die Würze gefiltert werden kann. Der Treber dient hier als zusätzlicher Filter, weshalb die Läuterruhe eingehalten werden sollte, damit sich der Treber absetzen kann. Für den Läuterbottich könnt ihr den gleichen Topf verwenden, wie für den Braukessel",
        content2: "Außerdem benötigt ihr noch einen ½“ Auslasshahn und die schon erwähnte Läuterschlange.",
        content3: "Und natürlich eine Dichtung, die ihr zwischen T-Stück der Läuterschlange und Topfwand anbringt. Der Auslasshahn wird direkt in das T-Stück geschraubt und festgezogen. Damit sollte alles Dicht sein.",
        content4: "Wir haben hier den Hahn mit einer Kontermutter am Topf befestigt und dann direkt eingeschweißt, da wir hier auch Probleme mit der Dichtigkeit erwartet hatten. Das Gewinde des Auslasshahns reicht noch weit genug in den Topf, um das T-Stück der Läuterschlange darauf zu schrauben – dabei ist darauf zu achten den Hahn so hoch anzubringen, dass sich das T-Stück im Topfinneren noch drehen lässt.",
        content5: "",
        content6: "",
        image1: require("./img/lauter_tun.png"),
        image2: require("./img/valve_1_2.PNG"),
        image3: "",
        image4: require("./img/purifier.png"),
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 3,
        title: "Hockerkocher",
        content1: "Um den Inhalt des Braukessels zu erhitzen, bietet sich ein Gaskocher an. Genauer gesagt einen Hockerkocher. Dieser sollte genug Leistung mitbringen, die große Menge an Brauwasser bzw. Maische zu erhitzen. Für 50l Ausschlagwürze verwenden wir einen Kocher mit 10kW Leistung.  Der Kocher sollte nicht der Günstigste sein. Diesen Fehler haben wir gemacht und nach dem 1. Einsatz hatte er schon den Geist aufgegeben.",
        content2: "Außerdem solltet ihr den Kocher auf einem Feuerfesten Untergrund betrieben, da auch sehr viel Hitze nach untern abstrahlt – auch hier haben wir unsere Erfahrungen gemacht.",
        content3: "Ein befreundeter Brauer hat uns auch von seinen Erfahrungen mit einer Herdplatte erzählt. Wurde damit aber nicht glücklich, da diese sehr lange braucht, um den Braukessel auf Temperatur zu bringen.",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/stool_cooker.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 4,
        title: "Einkochautomat",
        content1: "Um den Nachguss auf die benötigten 78°C zu bringen verwenden wir einen Einkochautomat mit 27l Fassungsvermögen. Da wir immer 50l Ausschlagwürze anstreben ist dieser allerdings ein wenig zu klein, weshalb wir unseren Nachguss immer auf 2x erhitzen müssen und dadurch eine kleine Pause beim Läutern haben. Um das Ganze zu beschleunigen verwenden wir noch einen Wasserkocher zusätzlich und mischen kaltes mit kochendem Wasser. Dabei ist aber immer darauf zu achten, dass am Ende 78°C erreicht werden.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/preserving_cooker.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 5,
        title: "Braupaddel",
        content1: "Das Braupaddel ist eigentlich nichts anderes als ein großer Kochlöffel. Es wird in verschiedenen Ausführungen und Werkstoffen angeboten. Ihr solltet darauf achten, dass das Paddel lange genug ist, um bis zum Boden des Braukessels zu reichen, ohne dass ihr euch die Finger in der Maische verbrennt. Es ist wichtig immer die gesamte Maische umzurühren. Also auch alles was sich am Boden befindet, da euch sonst das Malz das sich absetzt anbrennt.",
        content2: "Wir haben uns für ein Braupaddel aus Holz entschieden. Die aus Kunststoff oder Edelstahl sind vielleicht etwas leichter zu reinigen, aber hygienisch sind alle. Allerdings werden die aus Holz und Kunststoff nicht so Warm wenn sie über eine lange Zeit beim Rühren der Maische verwendet werden.",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: "",
        image2: require("./img/brew_paddle.png"),
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "",
        link2: "2",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 6,
        title: "Messbecher",
        content1: "Ein großer Messbecher ( min. 2l ) ist ein Muss. Er wird benötigt um die Wassermengen abzumessen (sollte im Topf keine Füllstandskala sein). Auch beim Nachguss findet er Verwendung. Zum einen kann damit der Nachguss langsam und gleichmäßig in den Läuterbottich gegeben werden und zum anderen habt ihr immer die Wassermenge im Blick die ihr beim Läutern schon in den Läuterbottich nachgegossen habt.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/measuring_cup.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 7,
        title: "Refraktometer",
        content1: "Ein Refraktometer wird benötigt, um den Brix-Wert – also den Zuckergehalt (°P) in eurer Maische bzw. Würze zu messen. Viele haben auch noch eine Skala für den Alkoholgehalt. Aber ihr müsst unbedingt daran denken, wenn ihr während der Gärung den Brix-Wert bestimmt, dass die gelöste Kohlensäure den Messwert verfälscht. Hier gibt es Korrekturtabellen die euch helfen den realen Wert zu ermitteln. Auch für den realen Zuckergehalt in der Würze gibt es Tabellen, denn Brix ist nicht gleich °P.",
        content2: "Außerdem solltet ihr darauf achten, dass das Refraktometer einen automatischen Temperaturausgleich hat. Das wird durch den Aufdruck ATC angegeben. Damit müsst ihr nicht so genau auf die Temperatur der Würze achten, denn auch die kann den Wert verfälschen.",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: "",
        image2: require("./img/refractometer.png"),
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "",
        link2: "2",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 8,
        title: "Messzylinder",
        content1: "Der Messzylinder wird für die Schnellvergärprobe verwendet. Dieser ist optional. Solltet ihr die Flaschengärung bevorzugen werdet ihr eher keine Schnellvergärprobe machen müssen. Solltet ihr euch für die Nachgärung in Kegfass entschieden dann muss eine Vergärprobe gemacht werden. Ihr könnt aber dafür auch einfach ein normales Glas verwenden. Wie die Schnellvergärprobe Funktioniert wird in „Der Weg zum Bier“ auf dieser Seite erklärt",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/measuring_cylinder.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 9,
        title: "Gärfass",
        content1: "Im Gärfass findet die Hauptgärung statt. Hier wird der Zucker zu Alkohol und Kohlensäure, die aber noch durch den Spundadapter am Fass entweichen kann. Die Kohlensäure die im fertigen Bier enthalten ist, entsteht erst bei der Nachgärung, die dann in der Flasche oder dem Kegfass stattfindet.",
        content2: "Das Gärfass sollte auf die Menge der Ausschlagwürze abgestimmt sein, damit auch die gesamte Würze hineinpasst – logisch.",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: "",
        image2: require("./img/fermentation_barrel.png"),
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "",
        link2: "2",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 10,
        title: "Kegfass",
        content1: "Solltet ihr euch entschließen die Nachgärung und Reifung in einem Kegfass stattfinden zu lassen, benötigt ihr auch ein solches. Die Fässer gibt es leer und gereinigt zu kaufen. Allerdings sind die nicht so günstig.",
        content2: "Die Einfachste Methode ist es ein volles Fass zu kaufen, es leer zu trinken und dieses dann zu verwenden. Damit habt ihr nur das Pfand für das Fass bezahlt. Das liegt meisten um die 50€.",
        content3: [<strong>Vor dem Öffnen des Fasses solltet ihr den Druck ablassen!</strong>, " Das könnt ihr mit dem Zapfkopf erledigen. Entweder ihr drückt den Hebel am Zapfkopf nur zur Hälfte nach unten, damit das Gas im Fass entweichen kann – das zischen verrät euch, dass es funktioniert – oder ihr lasst es über den Bierabgang am Zapfkopf ab. Das sollte gehen, wenn das Fass richtig leer ist. Das endet aber meistes mit einer Sauerei, da das Fass eigentlich nie ganz leer gezapft ist und damit auch immer Bier mit herausschießt."],
        content4: ["Zum Öffnen benötigt ihr dann noch einen Fassöffner. Die gibt es zu Kaufen – allerdings gibt es auch einfache Eigenkonstruktionen die denselben Zweck erfüllen. Es gibt auf Youtube gute Videos wie und mit welchen Mitteln sich so ein Fass öffnen lässt – ", <strong>Aber bitte nicht mit einer Rohrzange! Damit macht ihr das Fitting kaputt!!</strong>],
        content5: "",
        content6: "",
        image1: require("./img/keg_barrel.png"),
        image2: "",
        image3: "",
        image4: require("./img/keg_opener.png"),
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 11,
        title: "Zapfkopf",
        content1: "Einen Zapfkopf benötigt ihr um das Bier am Ende auch wieder aus dem Fass zu bekommen – wer hätte das gedacht. Der Zapfkopf hat einen Abgang für das Bier – der obere Anschluss – und einen Anschluss für das Co2 – seitlicher Anschluss – mit dem ihr das Bier beim Zapfen aus dem Fass herausdrückt.",
        content2: ["Außerdem benötigt ihr den Zapfkopf auch bei der Nachgärung, um Überschüssiges Co2 aus dem Fass zu lassen – Dafür wird ein Spundventil am Co2-Anschluss angebracht. ", <strong>Und nicht vergessen den Absperrhahn am Bierabgang zu schließen!!!</strong>, " Im Co2-Anschluss ist noch ein Rückschlagventil verbaut. Das ist eine Kunststofflippe die verhindern soll, dass Co2 oder Bier aus dem Fass zurück in die Co2-Leitung gedrückt wird. Bei der Gärung soll aber genau das passieren. Dafür müsst ihr etwas in den Anschluss hineinschieben das die Lippe offen hält. Ein Nagel reicht dafür aus. Er sollte sich aber auch wieder entfernen lassen. Und achtet darauf die Lippe nicht zu beschädigen. Normalerweise gibt es ein kleines Sichtglas an diesem Anschluss, in dem ihr die Lippe sehen könnt und dadurch wisst ob sie aufgedrückt wird"],
        content3: "Beim Zapfkopf müsst ihr darauf achten, welches Fitting euer Kegfass hat. Die meisten haben ein Flachfitting.",
        content4: "",
        content5: "",
        content6: "",
        image1: "",
        image2: "",
        image3: require("./img/tapping_head.png"),
        image4: "",
        image5: "",
        image6: "",
        link1: "",
        link2: "",
        link3: "3",
        link4: "",
        link5: "",
    },
    {
        id: 12,
        title: "Absperrhahn",
        content1: "Damit das Bier beim anschließen des Zapfkopfs nicht sofort durch den Bierabgang herausschießt solltet ihr euch einen Absperrhahn anschaffen und immer bevor der Zapfkopf auf das Fass kommt kontrollieren, dass dieser geschlossen ist. Der Absperrhahn wird einfach auf den Bierabgang aufgeschraubt. Dort kann er dann auch für alle Ewigkeit bleiben – Außer ihr müsst den Zapfkopf tauschen oder bei der Reinigung.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/shut-off_valve.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 13,
        title: "Spundventil",
        content1: "Das Spundventil wird benötigt, um bei der Fassgärung überschüssigen Druck abzulassen. Dazu verfügt das Spundventil über ein Barometer und ein Überdruckventil. Damit lässt sich der gewünschte Fassdruck einstellen. Sollte dieser steigen, öffnet das Überdruckventil bis der eingestellte Druck wieder erreicht ist. Wieviel Druck im Fass herrschen muss, um die richtige Menge Co2 in gelöster Form im Bier zu haben wird in der Sparte „Der Weg zum Bier“ beschrieben.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/bung_valve.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 14,
        title: "Adapter für Spundventil",
        content1: "Da das Spundventil nicht einfach auf den Co2-Anschluss des Zapfkopfes passt, benötigt ihr noch einen Adapter. Bei dem kommt es darauf an welches Gewinde am Zapfkopf und am Spundventil ist.",
        content2: "Es gibt eine Große Auswahl an Adaptern / Reduzierungen von verschieden Größen. Da sollte sich der passende Adapter finden lassen.",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: "",
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "",
        link2: "2",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 15,
        title: "Jod",
        content1: "Jod benötigt Ihr für die Jodprobe. Damit wird festgestellt, ob die Stärke aus dem Malz komplett in Zucker gewandelt wurde. Verfärbt sich das Jod, solltet ihr die Verzuckerungsrast um x Minuten verlängern. Näheres erfahrt Ihr im Bereich „Der Weg zum Bier“. Wir verwenden eine Jod N 50 Lösung für die Jodprobe.",
        content2: "Wenn man mal kein Brauerjod zur Hand hat, geht auch eine Jod-Tinktur aus der Apotheke – die ist allerdings teurer als das Brauerjod.",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/iodine.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "",
        link2: "2",
        link3: "",
        link4: "",
        link5: "",
    }
];

const uTappingData = [
    {
        id: 1,
        title: "Zapfhahn",
        content1: "Wer sein Bier aus dem Fass Zapfen möchte benötigt einen Zapfhahn. Diesen gibt es einzeln, dann muss man sich noch eine Halterung bauen, um ihn zu befestigen oder aber man kauft ihn komplett als Bierspender. Hier ist der Hahn in einer Säule verbaut.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/spigot.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 2,
        title: "Bier- und Co2-Schlauch",
        content1: "Um das Bier zum Zapfhahn zu befördern braucht es auch einen Bierschlauch, der am Bier-Abgang des Zapfkopfes und am Zapfhahn angeschlossen wird. Die Länge des Schlauches hängt davon ab wie weit das Fass vom Hahn entfernt ist. Normalerweise dürften 1-1.5m reichen. Außerdem braucht ihr Druck im Fass, um das Bier heraus zu befördern. Dafür muss eine Co2-Flasche am seitlichen Co2-Anschluss des Zapfkopfes angeschlossen werden. Auch hierfür gibt es eine Leitung. Die muss im Durchmesser nicht so stark sein wie die Bierleitung. Auch diese wird mit 1m ausreichend lang sein.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/hoses.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: "",
    },
    {
        id: 3,
        title: "Druckminderer",
        content1: "Einen Druckminderer benötigt ihr, um den Fassdruck beim Zapfen (oder abfüllen) zu regulieren. Er wird an der Co2-Flasche angeschlossen. Dieser besteht aus einem Arbeitsmanometer, welches den Druck anzeigt, der in das Fass gelangt, und einem Inhaltsmanometer. Das zeigt den Druck in der Gasflasche an. Das Manometer mit der Scala bis 250 oder 300 bar ist das Inhaltsmanometer. Das Arbeitsmanometer hat eine Scala bis ca. 6 bar.",
        content2: ["Wie man den richtigen Zapfdruck ermittelt erfahrt ihr ", <a href="https://www.lahnsteiner-brauerei.de/interessantes/Zapfdruck.htm" target="_blank" rel="noreferrer">hier</a> ],
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: "",
        image2: require("./img/pressure_reducer.png"),
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "https://www.lahnsteiner-brauerei.de/interessantes/Zapfdruck.htm",
        link2: "2",
        link3: "",
        link4: "",
        link5: "",
    }
];

const uBottlingData = [
    {
        id: 1,
        title: "Gegendruckabfüller",
        content1: "Wenn ihr euer Bier in Flaschen abfüllen wollt, benötigt ihr einen Gegendruckabfüller. Damit lässt sich die Flasche vor dem Befüllen mit Druck vorspannen, damit das Bier in der Flasche nicht aufschäumt.  Dann könnt ihr das Bier in die Flasche laufen lassen, indem der Druck langsam abgelassen wird. Ist die Flasche voll, muss der Bierfluss gestoppt, der restliche Druck abgelassen und die Flasche verschlossen werden. Das Abfüllen bedarf einiger Übung. Also verzweifelt nicht, wenn es nicht auf Anhieb klappt.",
        content2: "",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/backpressure filler.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: ""
    },
    {
        id: 2,
        title: "Co2-Verteiler",
        content1: "Da die Flasche zum Abfüllen mit demselben Druck wie im Fass vorgespannt werden muss, benötigt ihr noch einen Co2-Verteiler.  Damit benötigt ihr nur eine Co2 Flasche und könnt sicher sein, dass überall derselbe Druck herrscht.",
        content2: "Wir haben hierfür einfach einen Druckluftverteiler ( bzw. Verteilerblock ) genommen. Ihr solltet darauf Achten, dass die von euch verwendeten Co2-Schläuche in den Verteiler passen. Unserer ist für 6 mm Luftschläuche geeignet, da unsere Schläuche einen Außendurchmesser von 6 mm haben.",
        content3: "Es genügt aber auch ein Y- oder T-Steckverbinder für 6 mm Schläuche. Die sind ziemlich günstig.",
        content4: "",
        content5: "",
        content6: "",
        image1: require("./img/co2_distributor.png"),
        image2: "",
        image3: "",
        image4: "",
        image5: "",
        image6: "",
        link1: "1",
        link2: "",
        link3: "",
        link4: "",
        link5: ""
    }
];

export {uBrewingData, uTappingData, uBottlingData};