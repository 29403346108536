
import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./Header";
import Home from "./Home";
import History from "./History";
import WayToBeerAc from "./WayToBeerAc";
import Utensils from "./Utensils";
import Impressum from "./Impressum";
import About from "./About";
import NoPage from "./NoPage";
import Footer from "./Footer";
//import sitemap from "./sitemap.xml";
import { useEffect, useReducer } from 'react';
import { CookieModal } from '@schlomoh/react-cookieconsent'
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from "react-ga";

// Cookie Banner Content + Styles
const Content = () => (
  <>
    <h4>Diese Seite verwendet Cookies</h4>
    <p>Wir verwenden Cookies, um uns ständig zu verbessern und euch das bestmögliche Online-Erlebnis zu bieten.</p> 
    <p>Für die Analyse verwenden wir auch Google Analytics, einen Webanalysedienst von Google Inc. Nähere Informationen findet Ihr im <a href="/impressum">Impressum</a> unter "Google Analytics"</p>
  </>
);
const primaryButtonStyle={backgroundColor: '#a1a1a1'};
const secondaryButtonStyle={backgroundColor: '#ff4444'};

function App() {

  // google Analytics nur verwenden wenn Cookies akteptiert und GA initialisiert
  const [userRequest, setUserRequest] = useReducer((userRequest, newUserRequest) => ({...userRequest, ...newUserRequest}), {accepted: false, visible: true}); // useState Hook 
   // hole aktuell angezeigte Seite
  const location = useLocation(); 
  // lade User-Eingaben in Variablen  
  const {accepted, visible} = userRequest;

  // User-Eingabe Alle Akzeptiert oder Notwendige Akzeptiert
  const onAccept = (ICookieObject) => {

    // Google Analytics nur wenn Akzeptiert
    if(ICookieObject.Analytics === true){
      ReactGA.initialize("G-8C9M9M034D");
      // google Analytics nur verwenden wenn Cookies akteptiert und GA initialisiert
      setUserRequest({accepted: true});
    };
  };

  // User-Eingabe Alle Abgelehnt
  const onDecline= () => {
    setUserRequest({accepted: false});
  };

  // useEffect() damit useState nicht neu Rendern veranlasst - so wird setVisible nur einmal gesetzt
  useEffect(()=> {
    // zeige cookie banner nicht in Impressum - auf allen anderen Seiten wird cookie banner angezeigt, bis von User akzeptiert oder abgelehnt
    if(location.pathname === "/impressum"){
      setUserRequest({visible: false});
    }else{
      setUserRequest({visible: true});
    };
  }, [location.pathname]);
  
  // aktiviere Google Analytics cookies
  if(accepted){
        ReactGA.pageview(window.location.pathname + window.location.search);
      };

  return (

    <div style={{margin : "-16px"}}>
      
      {/* Cookie Banner aufrufen, wenn nicht Impressum angezeigt wird */}
      {visible &&  <CookieModal 
            enableManagement
            cookieCategories={['Analytics']}
            onAccept={onAccept} 
            onDecline={onDecline}
            acceptButtonText='Alle Akzeptieren'
            declineButtonText='Alle Ablehnen'
            managementButtonText='Einstellungen'
            primaryButtonStyle={primaryButtonStyle}
            secondaryButtonStyle={secondaryButtonStyle}
            infoContent={<Content />}
            managementContent={<Content />}
        />}
      
      <div>
          <link rel="stylesheet" href="styles.css"/>
          <Header />
      </div>
      {/* <BrowserRouter> */}
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/history" element={<History />} />
          <Route path="/waytobeer" element={<WayToBeerAc />} />
          <Route path="/utensils" element={<Utensils />} />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/about" element={<About />} />
          <Route path="/sitemap.xml" element={<sitemap />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      {/* </BrowserRouter> */}
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;

