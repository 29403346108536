import { Card, Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWheatAlt } from '@fortawesome/free-solid-svg-icons'
import EbCardImage from "../img/ebBkgnd.png"
import HCardImage from "../img/history.png"
import WTBCardImage from "../img/wayToBeer.png"
import UCardImage from "../img/Utensilen.png"


function Home(){

    const wheatIcon225 = <FontAwesomeIcon icon={faWheatAlt} transform={{ rotate: 225 }} />
    const wheatIcon45 = <FontAwesomeIcon icon={faWheatAlt} transform={{ rotate: 45 }} />

    return(
        <div className="content-container">
            <Container fluid className="d-flex flex-column h-100 conCardSection">
            {/* <Row> */}
                <section className="welcomeSection">
                    <Card className="text-center mt-5 mb-5 bg-transparent border-0 welCard">
                            <Card.Body>
                                <Card.Img className="mb-5" variant="top" src={EbCardImage} style={{width: "5rem"}}/>
                                <Card.Title className="mb-5"><h1>Willkommen auf Expedition Bier</h1></Card.Title>
                                <Card.Text className="welCardText"><h5><span>Wenn euch das Thema Bier brauen auch schon immer interessiert hat, seid ihr hier genau richtig.</span><br /><br />
                                <span>Bier brauen als Hobby wird immer beliebter und alleine in Deutschland gibt es 890 Mikrobrauereien<br /> (Stand 2021). 
                                Das beinhaltet jedoch noch nicht die Hobbybrauer. Diese Anzahl dürfte noch ein ganzes Stück höher sein.</span><br /><br />
                                <span>Das Brauen des eigenen Bieres ist eine tolle Art sein Bier genau nach seinem eigenen Geschmack<br /> 
                                herzustellen und es werden einem dabei so gut wie keine Grenzen gesetzt.<br /><br />
                                Auf dieser Seite möchte ich euch die Geschichte des Bieres, dessen Herstellung und die dafür benötigten Utensilien etwas näherbringen.</span></h5>
                                </Card.Text>
                            </Card.Body>
                    </Card>
                </section>
            {/* </Row> */}
            </Container>
            <Container fluid className="d-flex flex-column h-100">
            {/* <Row> */}
                <section className="cardSection">
                    <Container className="mx3 conCardSection">
                        <div>
                            <h1 className="text-center mb-5 pt-5">{wheatIcon225} Bierwissen {wheatIcon45}</h1>
                        </div>
                        <Row>
                            <Col className="mb-3" lg={4} md={6} xs={12}>
                                <Link to="/history" className="cardLink">
                                    <Card className="text-center h-100 cardSelArea" href="./History.js">
                                        <Card.Img className="mb-3" variant="top" src={HCardImage} style={{width: "100%"}}/>
                                        <Card.Body>
                                            <Card.Title className="mb-3 card-title"><h3>Die Geschichte des Bieres</h3></Card.Title>
                                            <Card.Text>
                                                <span>In Europa ist das Bier seit dem 3. Jahrtausend vor Christus bekannt und wurde auch bei Funden in Gräbern im dänischen Ostjütland nachgewiesen.
                                                Jedoch ist das nicht der Beginn des Bier brauens. Die Wurzeln reichen noch viel weiter zurück.
                                                Einen Einblick in die Geschichte des Brauhandwerks findet ihr hier</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col className="mb-3" lg={4} md={6} xs={12}>
                                <Link to="/waytobeer" className="cardLink">
                                    <Card className="text-center h-100 cardSelArea">
                                        <Card.Img className="mb-3" variant="top" src={WTBCardImage} style={{width: "100%"}}/>
                                        <Card.Body>
                                            <Card.Title className="mb-3 card-title"><h3>Der Weg zum Bier</h3></Card.Title>
                                            <Card.Text>
                                                <span>Es gibt einige Schritte die, für die Herstellung des eigenen Bieres, zu gehen sind. Diese sind wichtig,
                                                damit ihr am Ende ein leckes Ergebnis habt. Hier erfahrt ihr alles. Vom Mälzen bis zur Reifung.</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                            <Col className="mb-3" lg={4} md={12} xs={12}>
                                <Link to="/utensils" className="cardLink">
                                    <Card className="text-center h-100 cardSelArea">
                                        <Card.Img className="mb-3" variant="top" src={UCardImage} style={{width: "100%"}}/>
                                        <Card.Body>
                                            <Card.Title className="mb-3 card-title"><h3>Alles was man braucht</h3></Card.Title>
                                            <Card.Text>
                                                <span>Ein Handwerker ist nichts ohne sein Werkzeug. So auch der Bierbrauer. Um etwas gutes 
                                                herzustellen, braucht es die richtigen Utensilien. Damit euch die sucherei nach dem passenden
                                                Werkzeug erspart bleibt, gibt es hier eine Auflistung.</span>
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </section>
            {/* </Row> */}
            </Container>
       </div>
    );
};

export default Home;

