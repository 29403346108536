const historyData = [
    {
        id: 1,
        title: "Die Geschichte des Bieres",
        content1: "Viele Quellen geben den Beginn des Bierbrauens mit 4000-2000 Jahre vor Christus an. Jüngeren Ausgrabungen und Forschungen zufolge könnte die Entdeckung des Bieres jedoch schon 9000 vor Christus, mit der ersten Kultivierung des Getreides, stattgefunden haben.",
        content2: "Es wird vermutet, dass das Mälzen (Getreidekörner werden zum Keimen gebracht. Dadurch entstehen im Korn spezielle Enzyme, die zur Aufspaltung von Stärke in Zucker benötigt werden) schon sehr früh entdeckt wurde, da das Getreide nie komplett Trocken gelagert werden konnte.",
        content3: "Ein Fund des ältesten bekannten Braubetriebes in der Rakefek-Höhle, die sich im Norden Israels befindet, wird auf ein Alter von 12000-14000 Jahren datiert.",
        content4: "Aus der Zeit 3000-2000 v. Christus gibt es schriftliche Aufzeichnungen aus Mesopotamien (das Teile des heutigen Irak und Syriens umfasste) die schon 20 bekannte Biersorten belegen. Auch die alten Ägypter waren zu ihrer Zeit Bierbrauer. Das Bier war zusammen mit Brot eines der Grundnahrungsmittel und wurde z.B. Handwerkern als Lohn für ihre Dienste bezahlt. Das Gebräu war in allen Bevölkerungsschichten verbreitet. Vom Königshaus bis zum einfachen Arbeiter. Es galt als Grundnahrungsmittel und wurde auch den Toten als Grabbeigabe mitgegeben.",
        content5: "",
        content6: "",
        image: require("./img/stoneTubSD.png")
    },
    {
        id: 2,
        title: "",
        content1: "In Europa ist das Bier seit dem 3. Jahrtausend vor Christus bekannt und wurde auch bei Funden in Gräbern im dänischen Ostjütland nachgewiesen. Es war zu dieser Zeit weiter verbreitet als Met, der aus Honig hergestellt wird. Honig war schwerer zu beschaffen als das nötige Getreide für Bier und war entsprechend wertvoll, da er auch als Süßungsmittel diente.",
        content2: "Und selbst die Römer kamen durch die Germanen auf den Geschmack des Bieres. Es wurde, wie seit seiner Entdeckung, meist aus Gerste oder Weizen zusammen mit Wasser hergestellt. Jedoch wurden, um die Haltbarkeit und die Rauschwirkung zu erhöhen, verschiedene Zusatzstoffe, wie Eichenrinde, Myrte, Stechapfel oder Bilsenkraut beigemischt.",
        content3: "Der Hopfen, der zu der Familie der Hanfgewächse gehört, fand vermutlich erst im Mittelalter Verwendung im Bier.",
        content4: "Es wurden zwischenzeitlich in der Geschichte des Bieres viele fragwürdige Zutaten, wie Tollkirsche, Schlafmohn oder Bilsenkraut zum Brauen verwendet. ",
        content5: "Um der ungezügelten Panscherei Einhalt zu gebieten erließen viele Stadträte, Zünfte und Landesherren sogenannte Brauordnungen, die die Zutaten Gerste / Malz, Hopfen und Wasser als Inhaltsstoffe festschrieben.  Von der Hefe, die den Zucker zu Alkohol wandelt, wusste man zu dieser Zeit noch nichts. ",
        content6: "",
        image: ""
    },
    {
        id: 3,
        title: "",
        content1: "Es waren hauptsächlich Klöster die die Bierbraukunst weiter entwickelten und das Bier zu dem machten, welches wir heute kennen. In der Fastenzeit verwendeten die Mönche das Bier als Energielieferant. Im 17. Jahrhundert bekamen bayrische Mönche jedoch Zweifel, ob so ein Getränk das Fasten nicht breche und wollten sich zur Sicherheit den Segen des Papstes einholen. Daher wurde ein Fass Bier nach Rom geschickt. Auf der langen Reise verdarb das Gebräu allerdings und so kam es, dass der Papst nach dem Verkosten des Bieres die Leidensfähigkeit seiner bayrischen Brüder lobte und gab das Bier als Fastentrunk frei.",
        content2: "Das Bier wurde nun zum Volksgetränk und zu einem wichtigen Grundnahrungsmittel, sowie eine gute Einnahmequelle durch Steuern.",
        content3: "So war bis ins 19. Jahrhundert hinein eine warme Biersuppe ein gängiges Frühstück für Erwachsene und Kinder. Erst dann wurde sie durch Kaffee und Brot am Frühstückstisch abgelöst.",
        content4: "Das Bier entwickelte sich zu einer eigenen Industrie. Auch die heutige Getränkeindustrie hat ihre Wurzeln in der Bierbraukunst und Erfindungen wie die Kältemaschine, ein Vorreiter des Kühlschrankes,  von Carl Linde wurden hauptsächlich wegen der Brauereien und des Bieres gemacht.",
        content5: "Bier hat in seiner langen Geschichte und Tradition schon vieles bewegt und ist heute nicht mehr aus der Gesellschaft wegzudenken. ",
        content6: "",
        image:  require("./img/history.png")
    },
    {
        id: 4,
        title: "",
        content1: "Wer noch mehr und tiefere Einblicke in die Geschichte des Bieres oder des „Reinheitsgebots“ erhalten möchte findet sehr gute Artikel z.B. auf Wikipedia. ",
        content2: "Wir wollten hier nur einen kleinen Einblick in die Vergangenheit unseres liebsten Getränkes gewähren und euch vor Augen führen, dass Bier nicht einfach nur das Getränk der Wahl beim Fußball kucken ist, sondern einen festen Platz und Einfluss in der Geschichte der Menschheit hat.",
        content3: "",
        content4: "",
        content5: "",
        content6: "",
        image: ""
    }
];

export default historyData;