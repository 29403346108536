
function Footer(){

    let currentDate = new Date().getFullYear();

    return(
        <div className="mb-0 footer footer--pin">
            <footer>
                <div>
                    <ul className="nav justify-content-center border-bottom border-secondary mx-5">
                        <li className="nav-item"><a className="nav-link px-2 text-muted" href="/">Home</a></li>
                        <li className="nav-item"><a className="nav-link px-2 text-muted" href="/about">About</a></li>
                        <li className="nav-item"><a className="nav-link px-2 text-muted" href="/impressum">Impressum</a></li>
                    </ul>
                    <p className="text-center text-muted fcpyrgt"><small>&copy; Copyright {currentDate}, Kai Bittner</small></p>
                </div>
            </footer>
        </div>
    );
};

export default Footer;